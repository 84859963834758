import { sliderItemImages } from '../constants';
import { Link } from 'remix';

export const SliderContent = () => {
    return (
        <>
            <ul className='slideList'>
                {sliderItemImages.map((item) => (
                    <li className='slideItem' key={item.name}>
                        <Link to={item.url}>
                            <img src={`/images/shared/${item.path}`} alt={item.name} width="105" height="70" />
                        </Link>
                    </li>
                ))}
            </ul>
        </>
    )
}
