import Footer from "~/components/Footer";
import { FooterNavigation } from "~/components/FooterNavigation";
import { Header } from "~/components/Header";
import { Sidebar } from "~/components/Sidebar"
import { Scripts } from "remix";
import { useResponsive } from '../hooks/useResponsive';
import { LoaderFunction } from 'remix';
import { SliderContent } from "~/components/SliderContent";

export const loader: LoaderFunction = async () => {
    return {};
};

export const DefaultLayout: React.VFC<Props> = ({ children }) => {
    const { isMobile, isDesktop } = useResponsive();
    const adScript = `
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1922093970011434" crossorigin="anonymous"></script>
        <!-- スクエア広告 -->
        <ins class="adsbygoogle"
            style="display:block"
            data-ad-client="ca-pub-1922093970011434"
            data-ad-slot="8196863561"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
        <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
        </script>
    `;

    return (
        <>
            <body>
                <Header />
                <SliderContent />
                <div className="content">
                    <div className="contentMain defaultWrapper">
                        {children}
                    </div>
                    {isDesktop && <Sidebar />}
                </div>
                <div dangerouslySetInnerHTML={{ __html: adScript }} />
                <Footer />
                {isMobile && <FooterNavigation />}
                <Scripts />
            </body>
        </>
    );
};

